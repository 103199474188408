<template>
	<div class="admin-list">
		<CommonTable
			:total-items="totalItems"
			:total-pages="totalPages"
			:recent-filter="recentFilters"
			:type="tableType"
			@create="goToCreateForm"
			@search="search"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						>{{ header.title }}</th
					>
				</tr>
			</template>
			<template #body>
				<template v-if="admins && admins.length">
					<tr
						v-for="(admin, index2) in admins"
						:key="`${admin.id}-${index2}`"
						@click="goToAdmin($event, admin.id)"
						@click.right="goToAdminNewTab($event, admin.id)"
						@click.middle="goToAdminNewTab($event, admin.id)"
					>
						<td scope="row" class="table-no">{{ getNo(index2) }}</td>
						<td scope="row" class="text-break text-pre-wrap name">{{ admin.fullName }}</td>
						<td scope="row" class="text-break text-pre-wrap email">{{ admin.email }}</td>
						<td scope="row" class="text-break text-pre-wrap clinic">{{ getClinicName(admin) }}</td>
						<td scope="row">{{ labelRole(admin.role) }}</td>
						<td scope="row" class="text-break text-pre-wrap date-created">{{ admin.createdAt }}</td>
						<td scope="row" class="text-break text-pre-wrap creator"
							><router-link
								v-if="isAuthorized(admin)"
								:to="{ name: 'UpdateAdmin', params: { id: get(admin, 'creator.id') } }"
								target="_blank"
								class="link-icon link-redirect"
								>{{ getCreatorName(admin) }}</router-link
							><span v-else>{{ getCreatorName(admin) }}</span>
						</td>
						<td
							scope="row"
							:class="`text-break text-pre-wrap status ${getStatusClass(admin.status)}`"
							>{{ labelStatus(admin.status) }}</td
						>
						<td class="text-center"
							><router-link
								:to="{ name: 'UpdateAdmin', params: { id: admin.id } }"
								class="link-icon text-info"
								><span class="prefix-input d-inline-block"
									><font-awesome-icon
										:icon="['fas', 'eye']"
										:title="$t('Action.Edit')"
									/> </span></router-link
						></td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="12" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CommonTable>
	</div>
</template>

<script>
import { USER_ROLE, ADMIN_STATUS, MANAGEMENT } from "@/shared/plugins/constants"
import { GET_ADMINS } from "./../store/action-types"
import { RESET_FILTERS_ADMIN_LIST } from "./../store/mutation-types"
import { createNamespacedHelpers, mapState } from "vuex"
const { mapActions, mapMutations } = createNamespacedHelpers("admins")
import { get } from "lodash-es"

export default {
	name: "AdminList",

	components: {},

	props: {},

	data() {
		return {
			tableType: MANAGEMENT.ADMIN,

			headers: [
				{
					title: this.$t("AdminForm.No"),
					class: "fit",
				},
				{
					title: this.$t("AdminForm.Name"),
					class: "text-nowrap name",
				},
				{
					title: this.$t("AdminForm.Email"),
					class: "text-nowrap email",
				},
				{
					title: this.$t("AdminForm.PKManagement"),
					class: "text-nowrap clinic",
				},
				{
					title: this.$t("AdminForm.Permission"),
					class: "text-nowrap",
				},
				{
					title: this.$t("AdminForm.DateCreated"),
					class: "text-nowrap date-created",
				},
				{
					title: this.$t("AdminForm.Creator"),
					class: "text-nowrap creator",
				},
				{
					title: this.$t("AdminForm.Status"),
					class: "text-nowrap status",
				},
				{
					title: this.$t("Action.ViewEdit"),
					class: "text-nowrap",
				},
			],
		}
	},

	computed: {
		...mapState("admins", ["admins", "totalItems", "totalPages", "recentFilters"]),
		...mapState("authentication", ["currentUser"]),
	},

	watch: {},

	created() {},

	beforeDestroy() {
		if (!this.$route.path.includes("admins")) this.RESET_FILTERS_ADMIN_LIST()
	},

	methods: {
		get,

		...mapActions({ GET_ADMINS }),
		...mapMutations({ RESET_FILTERS_ADMIN_LIST }),

		search(params) {
			this.GET_ADMINS(params)
		},

		goToCreateForm() {
			this.$router.push({ name: "CreateAdmin" })
		},

		getClinicName(admin) {
			if (admin.role === USER_ROLE.SYSTEM_ADMIN) return this.$t("AdminForm.All")

			return admin?.clinic?.name
		},

		getCreatorName(admin) {
			if (!admin?.creator) return ""
			return `${this.labelRole(admin?.creator?.role)} ${admin?.creator?.fullName}`
		},

		labelStatus(value) {
			return {
				[ADMIN_STATUS.ACTIVE]: this.$t("Status.Active"),
				[ADMIN_STATUS.BANNED]: this.$t("Status.Inactive"),
			}[value]
		},

		labelRole(value) {
			return {
				[USER_ROLE.SYSTEM_ADMIN]: this.$t("AdminForm.SystemAdmin"),
				[USER_ROLE.SYSTEM_CLINIC]: this.$t("AdminForm.ClinicAdmin"),
				[USER_ROLE.SUPPORT_CLINIC]: this.$t("AdminForm.ClinicSupport"),
			}[value]
		},

		isAuthorized(admin) {
			if (
				this.currentUser?.role === USER_ROLE.SYSTEM_CLINIC &&
				admin.creator?.role === USER_ROLE.SYSTEM_ADMIN
			)
				return false
			return true
		},

		goToAdmin(event, adminId) {
			if (event.target.localName === "td") {
				this.$router.push({ name: "UpdateAdmin", params: { id: adminId } })
			}
		},

		goToAdminNewTab(event, adminId) {
			if (event.target.localName === "td") {
				let routeData = this.$router.resolve({
					name: "UpdateAdmin",
					params: { id: adminId },
				})
				window.open(routeData.href, "_blank")
			}
		},

		getStatusClass(status) {
			return {
				[ADMIN_STATUS.ACTIVE]: "status--active",
				[ADMIN_STATUS.BANNED]: "status--inactive",
			}[status]
		},

		getNo(no) {
			return no + 1 + (+this.recentFilters.offset || 0)
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.admin-list {
	table {
		.name {
			min-width: 125px;
			max-width: 150px;
		}
		.email {
			min-width: 150px;
			max-width: 150px;
		}
		.clinic {
			min-width: 100px;
			max-width: 150px;
		}
		.date-created {
			min-width: 100px;
			max-width: 125px;
		}
		.creator {
			min-width: 100px;
			max-width: 125px;
		}
		.status {
			min-width: 100px;
			max-width: 125px;
		}

		// .name,
		// .email,
		// .clinic {
		// 	white-space: nowrap !important;
		// 	overflow: hidden;
		// 	text-overflow: ellipsis;
		// }
	}

	tbody tr:hover {
		cursor: pointer;
	}
}

.status {
	&--active {
		color: $color-sea-green;
	}
	&--inactive {
		color: $color-red;
	}
}
</style>
